.header-div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title-div {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.icon-spacer {
    width: 20px;
}

.gh-side {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.gh-side-normal {
    width: 150px;
}
.gh-side-sm {
    width: 50px;
}

.divider {
    width: 100%;
}

.header-btn {
    width: 100%;
}

.btn-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.spacer {
    flex-grow: 1;
}
.btn-icon {
    flex-grow: 0;
}
.btn-span {
    flex-grow: 0;
    font-size: small;
}

