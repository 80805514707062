
.network-tab-root {

}

.divider {
    width: 100%;
}

.center-row {
    width: 100%;
    text-align: center;
}

.update-btn-div {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.update-btn-content {
    width: 100%;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.update-btn-span {
    flex-grow: 0;
    font-size: small;
}
.update-btn-icon {
    flex-grow: 0;
}

.small-note-div {
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}